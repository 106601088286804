import React from 'react';
import styles from './TopNav.module.css';
import Link from '../Link/Link'

const TopNav = () => (
  <div className={styles.TopNav}>
    <div className={styles.contents}>
      <Link href="/">Blue Tech 2</Link>
    </div>
  </div>
);

TopNav.propTypes = {};

TopNav.defaultProps = {};

export default TopNav;
