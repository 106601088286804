import React from 'react';
import { useState, useEffect } from 'react';
import './Apple.css';

const Apple = (props) => {

  const [isDirty, setIsDirty] = useState(false);
  const [textValue, setTextValue] = useState(props.sentTextValue);

  function handleChange(e) {
    setTextValue(e.target.value);
    setIsDirty(true);
  }
  
  useEffect(() => {

    const onLocationChange = (e) => {
      if (isDirty) {
        document.dispatchEvent(new CustomEvent('modalx', { detail: '/apple' }));
      } else {
        document.dispatchEvent(new CustomEvent('navconfirmed', {detail: e}));
      }
    }

    document.addEventListener('popstateb', onLocationChange);

    return () => {
      document.removeEventListener('popstateb', onLocationChange);
    }
    
  }, [isDirty]);

  return (
    <div className="applecomponent">
      <div className='contentComponents'>
        <input type="text" value={textValue} onChange={handleChange} />
      </div>
    </div>
  );
}

Apple.propTypes = {};

Apple.defaultProps = {};

export default Apple;
