import React from 'react';
import './SideNav.css';
import Link from "../Link/Link"

const SideNav = (props) => {
return (
    <div className="sidenav">
        <br/>
        <ul className="sidenavul">
            {/* <li><Link href="/apple">Apple</Link></li>
            <li><Link href="/banana">Banana</Link></li> */}
            <li><Link href="/activities">Activities</Link></li>
            {/* <li><Link href="/resources">Resources</Link></li> */}
            {/* <li><Link href="/grapes">Grapes</Link></li>
            <li><Link href="/mango">Mango</Link></li>
            <li><Link href="/strawberry">Strawberry</Link></li> */}
        </ul>
    </div>
 );
};
export default SideNav;