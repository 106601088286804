import React, {} from 'react'
import { useState, useEffect } from 'react';
import { formatDateToCustomFormat } from '../dateFormatter';

const Activities = (props) => {
  
  const [isDirty] = useState(false);
  const [externalPopup, setExternalPopup] = useState(null);
  const [activitiesData, setActivitiesData] = useState([]);

  useEffect(() => {

    if (!externalPopup) {
      return;
    }

    const timer = setInterval(() => {
      if (!externalPopup) {
        timer && clearInterval(timer);
        return;
      }
      const currentUrl = externalPopup.location.href;
      if (!currentUrl) {
        return;
      }
      const searchParams = new URL(currentUrl.replace('#', '?')).searchParams;
      const token = searchParams.get('access_token');
      if (token) {
        externalPopup.close();
        console.log(`The popup URL has URL access_token param = ${token}`);
        clearInterval(timer)

        // now that we have a token, fetch the activities (teamsnap events, etc.)
        const API_HREF = "https://api.teamsnap.com/v3/events/search?team_id=9060713";
        const requestOptions = {
          method: 'GET',
          headers: { 'Authorization': `Bearer ${token}` }
        };
        fetch(API_HREF, requestOptions)
            .then(response => response.json())
            .then(data => setActivitiesData(data));
        console.log(activitiesData);
      }
    }, 500)
  },
  [externalPopup]
)

  useEffect(() => {

    const onLocationChange = (e) => {
      if (isDirty) {
        document.dispatchEvent(new CustomEvent('modalx', { href: '/activities' }));
      } else {
        document.dispatchEvent(new CustomEvent('navconfirmed', {detail: e}));
      }
    }

    document.addEventListener('popstateb', onLocationChange);

    return () => {
      document.removeEventListener('popstateb', onLocationChange);
    }
    
  }, [isDirty]);

  // authenticate to TeamSnap API via OAUTH2
  const connectClick = (e) => {
    const bbwidth = 500;
    const bbheight = 400;
    const left = window.screenX + (window.outerWidth - 500) / 2;
    const top = window.screenY + (window.outerHeight - 400) / 2.5;
    const title = 'TeamSnap Auth';
    
    // use for https://localhost:3000 testing
    // const url = 'https://auth.teamsnap.com/oauth/authorize?client_id=TOgHP0AeHg7lkn1uqsOMAs2dKUGvqcrSi8qgSsi7-Tc&redirect_uri=https%3A%2F%2Flocalhost:3000&response_type=token&scope=read';
    
    // use for https://bluetech2demo.loehr.cloud testing
    const url = 'https://auth.teamsnap.com/oauth/authorize?client_id=3lLhAWg4gDQbz_SccjmHj4jfJRdRzP1hkPkob-uENXQ&redirect_uri=https%3A%2F%2Fbluetech2demo.loehr.cloud&response_type=token&scope=read';

    const popup = window.open(url, title, `width=${bbwidth},height=${bbheight},left=${left},top=${top}`);
    setExternalPopup(popup);
  }

  return <div><button onClick={connectClick}>Connect to TeamSnap</button>
  
  <table className="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col">Event</th>
          <th scope="col">Date / Time</th>
          <th scope="col">Location</th>
        </tr>
      </thead>

      <tbody>
        {activitiesData.collection && 
            activitiesData.collection.items.map((item) => (
             <tr>
             <td>{item.data[45].value}</td>
             <td>{formatDateToCustomFormat(item.data[3].value)}</td>
             <td>{item.data[43].value}</td>
           </tr>
        ))} 
      </tbody>

    </table>

  </div>

}

Activities.propTypes = {};

Activities.defaultProps = {};

export default Activities
