import React from 'react';
import styles from './Strawberry.module.css';
import { useState, useEffect } from 'react';

const Strawberry = () => {

  const [isDirty] = useState(false);

  useEffect(() => {

    const onLocationChange = (e) => {
      if (isDirty) {
        document.dispatchEvent(new CustomEvent('modalx', { href: '/strawberry' }));
      } else {
        document.dispatchEvent(new CustomEvent('navconfirmed', {detail: e}));
      }
    }

    document.addEventListener('popstateb', onLocationChange);

    return () => {
      document.removeEventListener('popstateb', onLocationChange);
    }
    
  }, [isDirty]);  

  return (
    <div className={styles.Strawberry}>
      <div className='contentComponents'>
        Strawberry Component
      </div>
    </div>
  )
}

Strawberry.propTypes = {};

Strawberry.defaultProps = {};

export default Strawberry;
