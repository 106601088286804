import React from "react";
import { useEffect } from "react";

const Link = ({ className, href, children }) => {

    // prevent full page reload
    const onClick = (event) => {
      event.preventDefault();
      window.history.pushState({}, "", href)
      document.dispatchEvent(new CustomEvent('popstateb', { detail: href }));
    };

    useEffect(() => {

      const onLocationChange = (e) => {

        let navsource = '';
        if (e.detail.detail) {
          navsource = e.detail.detail;
        } else {
          navsource = e.detail;
        }

        if (navsource === href) {
          // communicate to Routes that URL will be changed
          const navEvent = new PopStateEvent('popstate');
          window.dispatchEvent(navEvent);
          document.dispatchEvent(new CustomEvent('closemodal'));
        }
        
      }
  
      document.addEventListener('navconfirmed', onLocationChange);
      return () => {
        document.removeEventListener('navconfirmed', onLocationChange);
      }
      
    }, [href]);

  return (
    <a className={className} href={href} onClick={onClick}>
      {children}
    </a>
  );
};

export default Link;