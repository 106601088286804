import React, {} from 'react'
import { useState, useEffect } from 'react';

const Resources = (props) => {
  
  const [isDirty] = useState(false);

  useEffect(() => {

    const onLocationChange = (e) => {
      if (isDirty) {
        document.dispatchEvent(new CustomEvent('modalx', { href: '/resources' }));
      } else {
        document.dispatchEvent(new CustomEvent('navconfirmed', {detail: e}));
      }
    }

    document.addEventListener('popstateb', onLocationChange);

    return () => {
      document.removeEventListener('popstateb', onLocationChange);
    }
    
  }, [isDirty]);

  return <div>Resources</div>

}

Resources.propTypes = {};

Resources.defaultProps = {};

export default Resources
