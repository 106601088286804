import React from 'react';
import styles from './Home.module.css';
import { useEffect } from 'react';

const Home = () => {

  useEffect(() => {
    const onLocationChange = (e) => {
      document.dispatchEvent(new CustomEvent('navconfirmed', {detail: e}));
    }
    document.addEventListener('popstateb', onLocationChange);
    return () => {
      document.removeEventListener('popstateb', onLocationChange);
    }
  }, []);

  return (
    <div className={styles.Home}>
      <div className='contentComponents'>
        Home Component
      </div>
    </div>
  )
}

Home.propTypes = {};

Home.defaultProps = {};

export default Home;
