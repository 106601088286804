import './App.css';
import TopNav from '../src/TopNav/TopNav';
import SideNav from '../src/SideNav/SideNav';
import Route from './route';
import Home from './Home/Home';
import Apple from './Apple/Apple';
import Banana from './Banana/Banana';
import Grape from './Grape/Grape';
import Mango from './Mango/Mango';
import Strawberry from './Strawberry/Strawberry';
import Activities from './Activities/Activities';
import Resources from './Resources/Resources';

import Modal from './modal/modal';
import { useEffect, useState } from 'react';

import { AuthProvider } from 'oidc-react';

const oidcConfig = {
  onSignIn: () => {
    console.log("onSignIn hit...");
  },
  authority: 'https://cognito-idp.us-east-1.amazonaws.com/us-east-1_x33WCa9ox',
  clientId: '2o3r5ro8oe156h4fj2a3cssleg',
  redirectUri: 'https://bluetech2demo.loehr.cloud',
};

function App() {
  
  const [showModal, setShowModal] = useState(false);
  const [modalHref, setModalHref] = useState('');

  useEffect(() => {
    const handleModalx = (e) => {
      setModalHref(e.detail);
      setShowModal(true);
    }
    document.addEventListener('modalx', handleModalx);
    return () => {
      document.removeEventListener('modalx', handleModalx);
    }
  }, []);

  useEffect(() => {
    const handleCloseModal = (e) => {
      setShowModal(false);
    }
    document.addEventListener('closemodal', handleCloseModal);
    return () => {
      document.removeEventListener('closemodal', handleCloseModal);
    }
  }, []);

  return (
    // <AuthProvider {...oidcConfig}>
    <div id="app">
      <div id='topnav'><TopNav/></div>
      <div id='sidebyside' className="rowC">
        <div id='sidenav'><SideNav name="Greg" /></div>
        <div id='contents'>
          
            <Route path="/">
              <Home />
            </Route>
            <Route path="/apple">
              {/* <Apple sentTextValue={Math.random} /> */}
              <Apple />
            </Route>
            <Route path="/banana">
              <Banana />
            </Route>
            <Route path="/grapes">
              <Grape />
            </Route>
            <Route path="/mango">
              <Mango />
            </Route>
            <Route path="/strawberry">
              <Strawberry />
            </Route>
            <Route path="/activities">
              <Activities />
            </Route>
            <Route path="/resources">
              <Resources />
            </Route>
          <Modal show={showModal} modalMessage={'Discard changes?'} href={modalHref} />
        </div>
      </div>
    </div>
    // </AuthProvider>
  );
}

export default App;
