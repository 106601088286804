import React from 'react';

export default class Modal extends React.Component {

  render() {

    if (!this.props.show){
      return null;
    }

    const onCloseOk = (e) => {
      document.dispatchEvent(new CustomEvent('navconfirmed', { detail: this.props.href }));
    }

    const onCloseCancel = (e) => {
      document.dispatchEvent(new CustomEvent('closemodal'));
    }

    return (
      <div>
        <div id="cmodal">
          <div id="inner">
            <h3>Confirm...</h3>
            <div>{this.props.modalMessage}</div>
            <br/>
            <div id="buttonwrap">
              <button className="mbtns"
                onClick={e => {
                  onCloseOk(e);
                }}
                >OK</button>
              <button className="mbtns"
                onClick={e => {
                  onCloseCancel(e);
                }}
                >Cancel</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}